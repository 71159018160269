<template>
  <div v-loading="pageLoading">
    <div class="card mb-5 mb-xl-10" v-if="formData.master_option_id">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldOptionData.name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span v-if="oldOptionData.description" class="d-flex align-items-center text-gray-400 me-5 mb-2" v-html="oldOptionData.description.length > 500 ? oldOptionData.description.substring(0,500)+'...' : oldOptionData.description"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card pt-10">
      <div class="card-header border-0">
        <el-form
          @submit.prevent="submitForm()"
          :model="formData"
          :rules="rules"
          ref="formRef"
          class="w-100"
        >
        <div class="row g-9">
          <div class="col-md-6 fv-row">
            <div class="mb-6">
              <label class="required fs-6 fw-bold mb-2">{{ optionMasterConstants.OPTIONNAME }}</label>
              <el-form-item prop="option_name">
                <el-input size="large" v-model="formData.option_name" type="text" :placeholder="optionMasterConstants.OPTIONNAME"/>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-2 fv-row">
            <div class="mb-6">
              <label class="fs-6 fw-bold mb-2">{{ optionMasterConstants.OPTIONSORTORDER }}</label>
              <el-form-item prop="sort_order">
                <el-input-number v-model="formData.sort_order" size="large" controls-position="right"/>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-2 fv-row">
            <div class="mb-6">
              <label class="fs-6 fw-bold mb-2">{{ optionMasterConstants.OPTIONSTATUS }}</label>
              <el-form-item prop="status">
                <el-switch v-model="formData.status" class="ml-2" active-color="#13ce66" inactive-color="#ff4949" />
              </el-form-item>
            </div>
          </div>
        </div> 
        <div class="row g-9">
          <div class="col-md-6 fv-row">
            <div class="mb-6">
              <label class="fs-6 fw-bold mb-2">{{ optionMasterConstants.OPTIONDESC }}</label>
              <el-form-item prop="description">
                <el-input size="large"
                  v-model="formData.option_description"
                  :rows="4"
                  type="textarea"
                  :placeholder="optionMasterConstants.OPTIONDESC"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="row">
            <div class="col-xl-4">
              <h4>Option Attributes</h4>
            </div>
            <div class="col-xl-8">
                <button class="mb-2 btn btn-success float-end" @click="onAddItem"><i class="bi bi-plus-circle-fill fs-4"></i> {{ optionMasterConstants.ATTRIBUTE }}</button>
            </div>
          </div>
          <div class="row">
            <table class="table table-row-dashed fs-6 datatable border gy-2 gs-2" v-if="formData.keyData.length > 0">
              <thead>
                <tr class="text-start text-gray-800 fw-bolder fs-7 text-uppercase gs-0 text-center">
                  <th class="required">{{ optionMasterConstants.ATTRIBUTENAME }}</th>
                  <th class="required">{{ optionMasterConstants.ATTRIBUTESKUCODE }}
                    <el-tooltip class="box-item" effect="dark" :content="optionMasterConstants.ATTRIBUTESKUHELP" placement="right" raw-content>
                      <i class="bi bi-question-circle-fill"></i>
                    </el-tooltip>
                  </th>
                  <th class="required">{{ optionMasterConstants.ATTRIBUTEISDEFAULT }}</th>
                  <th class="required">{{ constants.SORTORDER }}</th>
                  <th class="required">{{ constants.STATUS }}</th>
                  <th>{{ constants.ACTIONS }}</th>
                </tr>
              </thead>
              <tbody class="fw-bold text-gray-600">
                <tr v-for="(labels, index) in formData.keyData" :key="index" v-bind:id="'row_'+index" align="center">
                  <td width="300">
                    <el-form-item
                      :key="'key' + index"
                      :prop="'keyData.' + index + '.attribute_name'"
                      :rules="{
                        required: true,
                        validator: checkEmptySpace,
                        message: optionMasterConstants.ATTRIBUTENAMEREQUIRED,
                        trigger: 'change',
                      }"
                    >
                    <el-input size="large"
                      v-model="labels.attribute_name"
                      :placeholder="optionMasterConstants.ATTRIBUTENAME"
                      :tabindex="index"
                    />
                    </el-form-item>
                  </td>
                  <td width="300">
                    <el-form-item
                      :key="'key' + index"
                      class="text-capitalize"
                      :prop="'keyData.' + index + '.attribute_code'"
                      :rules="{
                        required: true,
                        validator: skuValidate,
                        requiredmessage: optionMasterConstants.ATTRIBUTECODEREQUIRED,
                        trigger: 'change',
                      }"
                    >
                    <el-input size="large"
                      v-model="labels.attribute_code"
                      :placeholder="optionMasterConstants.ATTRIBUTESKUCODE"
                      class="text-capitalize"
                      :tabindex="index"
                    />
                    </el-form-item>
                  </td>
                  <td width="150">
                    <el-form-item 
                      :key="'is_default' + index"
                      :prop="'keyData.' + index + '.is_default'"
                    >
                    <el-radio v-model="labels.is_default" :label="1" @change="changeMasterDefaultVal($event, index)"/>
                    </el-form-item>
                  </td>
                  <td width="200">
                    <el-form-item
                      :key="'key' + index"
                      :prop="'keyData.' + index + '.attribute_sort_order'"
                    >
                      <el-input-number v-model="labels.attribute_sort_order" value="0" controls-position="right" :tabindex="index" size="large"/>
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :key="'key' + index"
                      :prop="'keyData.' + index + '.attribute_status'"
                    >
                    <el-switch
                      v-model="labels.attribute_status"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                    />
                    </el-form-item>
                  </td>
                  <td>
                    <el-form-item
                      :key="'key' + index"
                    >
                      <span v-if="labels.is_default=='1'">-</span>
                      <div v-else>
                        <button type="button" v-if="labels.delete_confirm == true" size="small" @click.prevent="remove(labels.master_attribute_id, index)" class="btn btn-icon btn-light btn-sm me-2">
                          <i class="bi bi-trash text-danger"></i>
                        </button>
                        <div v-else>
                          <button type="button" size="small" @click="deleteRow(index)" class="btn btn-icon btn-light btn-sm me-2">
                          <i class="bi bi-trash text-danger"></i>
                        </button>
                      </div>
                    </div>
                    </el-form-item>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row g-9">
          <div class="col-md-12 mb-5 mt-15">
            <button type="button" class=" btn btn-primary me-2" v-if="((permission.isEditAllowed) || (permission.isAddAllowed && permission.isEditAllowed))" :disabled="loading" @click="submitForm(formRef, 'save')">{{constants.SAVE}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
            <button type="button" class=" btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{constants.SAVEANDBACK}}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
            <button type="button" class=" btn btn-secondary me-2" @click.prevent="$router.push({ name: 'product-options'})">{{constants.CANCEL}}</button>
          </div>
        </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, onMounted, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { notificationFire } from "@/composable/notification.js";
import ApiService from "@/core/services/ApiService";
import globalConstant from "@/core/data/globalConstant.js";
import { formatText } from "@/core/helpers/common";
import { useRoute, useRouter } from "vue-router";
import { skuValidate } from "@/composable/common-validation.js";
import { ElMessageBox } from 'element-plus';
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import useEventBus from '../../composable/useEventBus'
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "add-product-option",

  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const formRef = ref(null);
    const loading = ref(false);
    const pageLoading = ref(false);
    const loadingback = ref(false);
    const disabled = ref(false);
    const route = useRoute();
    const router = useRouter();
    const masterOptionID = ref(0);
    const constants = globalConstant.general;
    const optionMasterConstants = globalConstant.optionMaster;
    const oldOptionData = reactive({
      name: '',
      description: ''
    });
    const formData = reactive({
      master_option_id: "",
      option_name: "",
      option_description: "",
      status: true,
      sort_order: 0,
      product_attributes: [],
      keyData: [
        {
          master_attribute_id: "",
          attribute_name: "",
          attribute_code: "",
          attribute_sort_order: 0,
          is_default: 1,
          attribute_status: ref(true),
          delete_confirm: ref(false),
        },
      ],
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
    })
    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "save"){
        submitForm(formRef, 'save', true)
      }else if(actionName=="save_back"){
        submitForm(formRef, 'save_back',true)
      }else if(actionName == "cancel"){
        router.push({ name: 'product-options'});
      }
    })
    //Validation for option field
    const checkEmptySpace = (rule, value, callback) => {
      if (value.trim() === "") {
        callback(new Error("Key name is required"));
      } else {
        callback();
      }
    };

    //Rules for product master option form
    const rules = ref({
      option_name: [
        {
          required: true,
          validator: checkEmptySpace,
          message: "Option name is required",
          trigger: "change",
        },
      ],
    });

    //For extran field tabledata used
    const tableData = reactive([
      {
        master_attribute_id: "",
        attribute_name: "",
        attribute_code: "",
        attribute_sort_order: 0,
        is_default: 0,
        attribute_status: true,
      },
    ]);

    //For attribute add more function
    const onAddItem = () => {
      formData.keyData.push({
        master_attribute_id: "",
        attribute_name: "",
        attribute_code: "",
        attribute_sort_order: 0,
        is_default: 0,
        attribute_status: true,
        delete_confirm: false,
      });
    };

    //For remove attribute row which are unsaved
    const deleteRow = (index) => {
      formData.keyData.splice(index, 1);
    };

    //On Attribute rows when change defaut radio button at that time this function call
    const changeMasterDefaultVal = (val, k) => {
      formData.keyData.forEach((obj) => {
        obj["is_default"] = 0;
      });
      formData.keyData[k]["is_default"] = val;
    };


    //Delete Product Master Attribute Record
    const remove = async (id, index) => {
      ElMessageBox.confirm(
        constants.DELETE_MESSAGE,
        constants.ALERT_WARNING,
        {
          confirmButtonText: constants.ALERT_CONFIMATION,
          cancelButtonText: constants.ALERT_CANCEL,
          type: 'warning',
          center: true
        }
      )
      .then(() => {
        ApiService.post("product-master-option/removeattribute/"+id)
        .then((response) => {
          let status = response.status;
          let message = response.data.message;
          if (status == 200) {
            notificationFire(message, 'success');
            formData.keyData.splice(index, 1);
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response != "") {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
      })
    };

    //Get All Product Master Details
    const getProductMasterDetail = async () => {
      if (typeof route.params.id != "undefined") {
      pageLoading.value = true;
        await ApiService.query("product-master-option/" + route.params.id)
          .then(({ data }) => {
            if (data.data) {
              oldOptionData.name = data.data.option_name;
              oldOptionData.description = data.data.option_description;
              formData.master_option_id = data.data.master_option_id;
              formData.option_name = data.data.option_name;
              formData.option_description = data.data.option_description;
              formData.sort_order = data.data.sort_order;
              formData.status = data.data.status == 1 ? true : false;
              if (data.data.attributes.length > 0) {
                formData.keyData = [];
                data.data.attributes.forEach((obj) => {
                  formData.keyData.push({
                    master_attribute_id: obj.master_attribute_id,
                    attribute_name: obj.attribute_name,
                    attribute_code: obj.sku_code,
                    attribute_sort_order: obj.sort_order,
                    attribute_status: obj.status == 1 ? true : false,
                    is_default: Number(obj.is_default),
                    delete_confirm: true,
                  });
                });
              }
            }
            pageLoading.value = false;
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404) {
                router.push({ name: 'product-options'})
              }
            }
            pageLoading.value = false;
          });
      }
    };

    //When submit form this function call
    const submitForm = async (formEl, btnName, isToolbarButtonsSubmitAction = false) => {
      formEl = (isToolbarButtonsSubmitAction) ? formEl.value : formEl;
      if (!formEl) {
        return;
      }
      formEl.validate((valid) => {
        if (!valid) return false;
        (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":true,"action":btnName}) : (btnName == "save")?(loading.value = true) : (loadingback.value = true);
        let attrDataLength = formData.keyData.length;
        //Your API call for save form data goes here
        let requestUrl =
          typeof route.params.id === "undefined"
            ? "product-master-option"
            : "product-master-option/" + route.params.id;
        ApiService.post(requestUrl, {
          _method: typeof route.params.id === "undefined" ? "POST" : "PUT",
          optionId: route.params.id,
          option_name: formData.option_name,
          option_description: formData.option_description,
          sort_order: formData.sort_order,
          product_attributes: formData.keyData,
          status: formData.status,
          attrDataLength: attrDataLength,
        })
          .then(({ data }) => {
            notificationFire(data.message, "success");
            oldOptionData.name = formData.option_name;
            oldOptionData.description = formData.option_description;
            (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
            if (data.data.attributes.length > 0) {
              let diff = data.data.attrDataLength - data.data.attributes.length;
              data.data.attributes.forEach((obj) => {
                formData.keyData[diff].master_attribute_id =
                  obj.master_attribute_id;
                formData.keyData[diff].delete_confirm = true;
                diff++;
              });
            }
            if (typeof route.params.id === "undefined") {
              setCurrentPageBreadcrumbs(formatText('edit-product-option'), [{"name" : formatText('product-options'), 'path': 'product-options'}, {"name": formatText('edit-product-option') , "active": true}]);
              router.push({
                name: 'edit-product-option', params: { id: data.data.master_option_id }
              });
            }
            if (btnName == "save_back") {
              router.push({ name: "product-options" });
            }
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            (isToolbarButtonsSubmitAction) ? emitEvent('loader',{"save_loader":false,"action":btnName}) : (btnName == "save")?(loading.value = false) : (loadingback.value = false);
          });
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name" :constants.SAVE, 'action': 'save','class': 'btn-primary me-3','isLoader': false }, {"name": constants.SAVEANDBACK , 'action': 'save_back','class': 'btn-primary me-3','isLoader':false }, {"name": constants.CANCEL , 'action': 'cancel','class': 'btn-secondary','isLoader':false }]);
    }
    //Set breadcrumbs
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" : formatText('product-options'), 'path': 'product-options'}, {"name": formatText(route.name) , "active": true}]);
      let role = loggedInUserRoles()
      let per = loggedInUserPermissions();
      if (per.includes("createProductMasterOption") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateProductMasterOption") || role == "Super Admin")
        permission.isEditAllowed = true;

      if (route.params.id != "undefined") { masterOptionID.value =  route.params.id; }
      if (masterOptionID.value > 0) {
        await getProductMasterDetail();
      }
     generateHeaderToolbarButtons();
    })
    onUpdated(async () => {
      generateHeaderToolbarButtons();
    })
    return {
      formData,
      rules,
      checkEmptySpace,
      skuValidate,
      submitForm,
      formRef,
      loading,
      pageLoading,
      loadingback,
      disabled,
      deleteRow,
      remove,
      changeMasterDefaultVal,
      tableData,
      onAddItem,
      constants,
      optionMasterConstants,
      permission,
      oldOptionData
    };
  },
};
</script>
<style sass>
  .el-radio__label {
    visibility: hidden;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  .text-capitalize .el-input__inner {
    text-transform: uppercase;
  }
  table .el-form-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  table .el-form-item__content {
    justify-content: center;
  }
  table .el-form-item__error {
    font-weight: 400;
  }
  .el-input-number {
    width: 50%;
  }
  table .el-input-number {
    width: 60%;
  }
</style>